
// Write your custom style. Feel free to split your code to several files

.section-feature {
	margin: 30px 20px 100px;
    border: 1px solid #f5f6f7;
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
}

.step .step-item:last-child .step-icon::before{
	display:none;
}

.social-white{
	color:$white;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	
	font-family: "Open Sans",sans-serif;
}

.uppercase{
	text-transform: uppercase;
}

.margin-top-2{
	margin-top:2rem;
}

.center-div{
	display: block !important;
	text-align: center !important;
}

@media (min-width:992px) {
	.mobile-only{
		display:none;
	}
}

.hr-nav{
	display:block; 
	height:5px; 
	width:75%;
}

@media (max-width:767px){
	.no-padding-left{
		padding-left:0px !important;
	}	
}

.center-button{
	display: block !important;
	margin:auto;
	margin-top:2rem;
}

.h-fullscreen {
	min-height: 100vh !important;
}

.nav-tabs .nav-link.active{
	border-color:transparent transparent #f1f2f3;
}

.nav-tabs .nav-link{
	padding:.25rem .25rem;
	border:0;
}

.clear-navbar{
	background-color: rgba(0,0,0,0);
	border:0;
}

.switch-position{
	margin-top:-40px !important;
}

.switch-label::before{
	border: 2px solid #50a1ff;
}
.switch-input:checked ~ .switch-label::before{
	border-color: #50a1ff;
}

.switch-label::after{
	background: #50a1ff;
}

@media (max-width:767px){
	#v-pills-color{
		white-space: nowrap;
	    overflow-x: scroll !important;
	    overflow-y: hidden;
	    width:auto;
	}
}

.custom-range-label{
	margin-bottom:0px;
}

#v-pills-hand input.active{
	background-color: #50a1ff;
	color:white;
}

#v-pills-color input.active{
	border: 7px solid rgba(0,0,0,0.15);
}

#pen-canvas {
    outline:none;
}

#matteSwitchWrapper .switch-label::after{
	top:0px;
	left:-135px;
	position:relative;
}
#matteSwitchWrapper .switch-input:checked ~ .switch-label::after{
	left:-115px;
}
.i-line{
	display:inline-block;
	vertical-align:middle;
}

.nav-div {
    display: inline-block;
    vertical-align:middle;
    height: 16px;
    width: 1px;
    background-color: rgba(117,117,117,0.8);
    margin: 0 1rem;
    opacity: 0.15;
}

.pt-10px{
	padding-top:10px;
}



.sticky-sidebar{
	z-index:999;
	top:70px;
}








/*slick slider*/

.slick-dots{
	position:static;
	display:block;
}

.slick-dots li {
	margin: 0 30px;
}

@media (min-width:500px){
	.slick-dots li{
		margin:0 90px;
	}
}



#slider .slide img{
    width: 100%;
}

#slider-menu .slick-dots li button{
    width: 100%;
    height: 40px;
    background-color: transparent;
    font-size: 16px;
    color: #d3ddf0;
    font-weight: 600;
}

#slider-menu .slick-dots li.slick-active button{
	color: #f9fafb;
    background-color: #000000d1;
}

/*overlay progress bar center-align*/
#offcanvas{
	display: flex;
    align-items: center;
    justify-content: center;
}







/*overlay loader animation*/
.Cube {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 50px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 0.5s 0.1s;
  transition: transform 0.5s 0.1s;
  perspective: 9999px;
  color: #333;
  margin: -40px 0 0 -40px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  margin-left: -37px;
  top: 65px;
  opacity: 1;

  &.panelLoad {
    z-index: 11;
    top: 50%;
    margin-top: -60px;
    -webkit-animation: panel 2.2s infinite forwards;
    animation: panel 2.2s infinite forwards;

    .cube-face {
      color: black;
      box-shadow: inset 0 0 0 1px #111, 0 0 1px 1px #111;
    }
  }

  .cube-face {
    width: inherit;
    height: inherit;
    position: absolute;
    background: white;
    box-shadow: inset 0 0 0 1px #333, 0 0 1px 1px #333;
    opacity: 1;
  }

  .cube-face-front {
    transform: translate3d(0, 0, 40px);
    -webkit-transform: translate3d(0, 0, 40px);
    font-size: 57px;
  }

  .cube-face-back {
    -webkit-transform: rotateY(180deg) translate3d(0, 0, 40px);
            transform: rotateY(180deg) translate3d(0, 0, 40px);
  }

  .cube-face-left {
    -webkit-transform: rotateY(-90deg) translate3d(0, 0, 40px);
            transform: rotateY(-90deg) translate3d(0, 0, 40px);
  }

  .cube-face-right {
    -webkit-transform: rotateY(90deg) translate3d(0, 0, 40px);
            transform: rotateY(90deg) translate3d(0, 0, 40px);
  }

  .cube-face-top {
    -webkit-transform: rotateX(90deg) translate3d(0, 0, 40px);
            transform: rotateX(90deg) translate3d(0, 0, 40px);
  }

  .cube-face-bottom {
    -webkit-transform: rotateX(-90deg) translate3d(0, 0, 40px);
            transform: rotateX(-90deg) translate3d(0, 0, 40px);
  }
}

@-webkit-keyframes panel {
  0% {
    -webkit-transform: rotateY(0deg) rotateZ(0deg);
  }
  20% {
    -webkit-transform: rotateY(90deg) rotateZ(0deg);
  }
  40% {
    -webkit-transform: rotateX(45deg) rotateZ(45deg);
  }
  60% {
    -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
  }
  80% {
    -webkit-transform: rotateX(310deg) rotateZ(230deg);
  }
  100% {
    -webkit-transform: rotateX(360deg) rotateZ(360deg);
  }
}

@keyframes panel {
  0% {
    -webkit-transform: rotateY(0deg) rotateZ(0deg);
    transform: rotateY(0deg) rotateZ(0deg);
  }
  20% {
   -webkit-transform: rotateY(90deg) rotateZ(0deg);
   transform: rotateY(90deg) rotateZ(0deg);
  }
  40% {
   -webkit-transform: rotateX(45deg) rotateZ(45deg);
   transform: rotateX(45deg) rotateZ(45deg);
  }
  60% {
   -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
   transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
  }
  80% {
   -webkit-transform: rotateX(310deg) rotateZ(230deg);
   transform: rotateX(310deg) rotateZ(230deg)
  }
  100% {
   -webkit-transform: rotateX(360deg) rotateZ(360deg);
   transform: rotateX(360deg) rotateZ(360deg)
  }
}






/* carrot-pen.html */
.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

#model-wrapper{
  height:60vh;
  width:100vw;
  max-width:100%;
}

.mesh-color-button{
  display:inline-block;
  width:70px;
  height:70px;
  border:2px solid #7f8c8d;
}

.mesh-hand-button{
  display:inline-block;
  width:70px;
  height:70px;
  border:2px solid #7f8c8d;
  background-color: #f3f3f3;
  font-size:30px;
  padding:0px;
}

#btn-blue{
  background-color: #3498db;
}
#btn-red{
    background-color: #e74c3c;
}
#btn-green{
    background-color: #2ecc71;
}
#btn-purple{
    background-color: #9b59b6;
}
#btn-yellow{
    background-color: #f1c40f;
}
#btn-turquoise{
    background-color: #1abc9c;
}
#btn-orange{
    background-color: #e67e22;
}


